import "./assets/scss/main.scss";

function run() {
  let fName = arguments[0],
    aArgs = Array.prototype.slice.call(arguments, 1);
  try {
    fName.apply(window, aArgs);
  } catch (err) {}
}
function _chart() {
  $(".b-skills").appear(() => {
    setTimeout(() => {
      $(".chart").easyPieChart({
        easing: "easeOutElastic",
        delay: 3000,
        barColor: "#FFEC01",
        trackColor: "#fff",
        scaleColor: false,
        lineWidth: 12,
        trackWidth: 12,
        size: 152,
        lineCap: "round",
      });
    }, 150);
  });
}
$(document).ready(() => {
  run(_chart);
});

$(document).ready(() => {
  $("#accordeon .service-item.head").on("click", f_acc);
});

function f_acc() {
  $(this).parent().toggleClass("active");
  if ($(this).parent().hasClass("active")) {
    $(this).next().slideToggle(777);
  } else {
    $(this).next().slideUp(777);
  }
}

const mobBtn = document.querySelector(".mob-nav");
const hamburger = document.querySelector(".hamburger");
const headerNav = document.querySelector(".section-header .nav");
mobBtn.addEventListener("click", () => {
  hamburger.classList.toggle("active");
  headerNav.classList.toggle("active");
  let bodyElement = document.body;
  bodyElement.classList.toggle("is-modal-open");
});

const optionMenu = document.querySelector(".select-menu");
if (optionMenu) {
  const selectBtn = optionMenu.querySelector(".select-btn"),
    options = optionMenu.querySelectorAll(".option"),
    sBtn_text = optionMenu.querySelector(".sBtn-text");
  selectBtn.addEventListener("click", () =>
    optionMenu.classList.toggle("active")
  );
  options.forEach((option) => {
    option.addEventListener("click", () => {
      let selectedOption = option.querySelector(".option-text").innerText;
      let selectedInput = document.getElementById("typeForm");
      sBtn_text.innerText = selectedOption;
      selectedInput.value = selectedOption;
      optionMenu.classList.remove("active");
      selectBtn.classList.remove("error");
      document.querySelector(".select-menu .error-message").innerHTML = "";
    });
  });

  var checkbox = document.getElementById("checkbox-agree");
  var checkboxLabel = document.getElementById("checkboxLabel");
  checkbox.addEventListener("change", () => {
    if (checkbox.checked) {
      checkboxLabel.classList.remove("error");
    }
  });
  document.addEventListener("click", (event) => {
    if (!optionMenu.contains(event.target)) {
      optionMenu.classList.remove("active");
    }
  });
}

const showModal = document.querySelector(".modal-show");
showModal.addEventListener("click", () => {
  let modal = document.querySelector(".modal-form");
  modal.classList.toggle("is-active");
  let bodyElement = document.body;
  bodyElement.classList.toggle("is-modal-open");
  hamburger.classList.remove("active");
  headerNav.classList.remove("active");
});

const showModalChat = document.querySelector(".btn-chat");
showModalChat.addEventListener("click", () => {
  let modal = document.querySelector(".modal-form");
  modal.classList.toggle("is-active");
  let bodyElement = document.body;
  bodyElement.classList.toggle("is-modal-open");
  hamburger.classList.remove("active");
  headerNav.classList.remove("active");
});
const closeModal = document.querySelector(".ico-close-modal");
closeModal.addEventListener("click", () => {
  let modal = document.querySelector(".modal-form");
  modal.classList.remove("is-active");
  let bodyElement = document.body;
  bodyElement.classList.remove("is-modal-open");
  hamburger.classList.remove("active");
  headerNav.classList.remove("active");
});

const optionMenuModal = document.querySelector(".select-menu-modal"),
  selectBtnModal = optionMenuModal.querySelector(".select-btn-modal"),
  optionsModal = optionMenuModal.querySelectorAll(".option-modal"),
  sBtn_textModal = optionMenuModal.querySelector(".sBtn-text-modal");

selectBtnModal.addEventListener("click", () =>
  optionMenuModal.classList.toggle("active")
);
optionsModal.forEach((option) => {
  option.addEventListener("click", () => {
    let selectedOption = option.querySelector(".option-text-modal").innerText;
    let selectedInput = document.getElementById("typeFormModal");
    sBtn_textModal.innerText = selectedOption;
    selectedInput.value = selectedOption;
    optionMenuModal.classList.remove("active");
    document.querySelector(".select-menu-modal .error-message").innerHTML = "";
    selectBtnModal.classList.remove("error");
  });
});

document.addEventListener("click", (event) => {
  if (!optionMenuModal.contains(event.target)) {
    optionMenuModal.classList.remove("active");
  }
});
let checkboxModal = document.getElementById("checkbox-agree-modal");
let checkboxLabelModal = document.getElementById("checkboxLabelModal");
checkboxModal.addEventListener("change", () => {
  if (checkboxModal.checked) {
    checkboxLabelModal.classList.remove("error");
  }
});

$(document).ready(() => {
  let inputElements = document.querySelectorAll("input");
  inputElements.forEach(function (inputElement) {
    inputElement.addEventListener("input", function (event) {
      let inputValue = event.target.value;
      if (inputValue) {
        inputElement.classList.remove("error");
        if (
          inputElement.nextElementSibling &&
          inputElement.nextElementSibling.classList.contains("error-message")
        ) {
          inputElement.nextElementSibling.innerHTML = "";
        }
      }
    });
  });

  var phoneFormValid = false;
  let inputFooter = document.querySelector("#phone");
  let inputModal = document.querySelector("#phoneModal");
  if (inputFooter) {
    let iti = window.intlTelInput(inputFooter, {
      allowDropdown: false,
      showFlags: false,
      initialCountry: "auto",
      autoPlaceholder: "aggressive",
      nationalMode: false,
      utilsScript:
        "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.12/js/utils.js",
      geoIpLookup: function (callback) {
        fetch("https://ipapi.co/json")
          .then((res) => {
            return res.json();
          })
          .then((data) => {
            callback(data.country_code);
          })
          .catch(() => {
            callback("us");
          });
      },
    });
    const output = document.querySelector(".error-message.phone");

    const handleChangeFooterPhone = () => {
      let text;
      if (inputFooter.value) {
        phoneFormValid = iti.isValidNumber();
        text = iti.isValidNumber() ? "" : "Invalid number - please try again";
      } else {
        text = "Please enter a valid number below";
      }
      if (phoneFormValid) {
        inputFooter.classList.remove("error");
      }
      const textNode = document.createTextNode(text);
      output.innerHTML = "";
      output.appendChild(textNode);
    };
    inputFooter.addEventListener("change", handleChangeFooterPhone);
    inputFooter.addEventListener("keyup", handleChangeFooterPhone);
  }

  if (inputModal) {
    let iti = window.intlTelInput(inputModal, {
      allowDropdown: false,
      showFlags: false,
      initialCountry: "auto",
      autoPlaceholder: "aggressive",
      nationalMode: false,
      utilsScript:
        "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.12/js/utils.js",
      geoIpLookup: function (callback) {
        fetch("https://ipapi.co/json")
          .then((res) => {
            return res.json();
          })
          .then((data) => {
            callback(data.country_code);
          })
          .catch(() => {
            callback("us");
          });
      },
    });
    const output = document.querySelector(".error-message.phone-modal");

    const handleChangeFooterPhone = () => {
      let text;
      if (inputModal.value) {
        phoneFormValid = iti.isValidNumber();
        text = iti.isValidNumber() ? "" : "Invalid number - please try again";
      } else {
        text = "Please enter a valid number below";
      }
      if (phoneFormValid) {
        inputModal.classList.remove("error");
      }
      const textNode = document.createTextNode(text);
      output.innerHTML = "";
      output.appendChild(textNode);
    };
    inputModal.addEventListener("change", handleChangeFooterPhone);
    inputModal.addEventListener("keyup", handleChangeFooterPhone);
  }

  $("form").submit(function (event) {
    event.preventDefault();
    let formID = $(this).attr("id");
    let formNm = $("#" + formID);
    let clikedForm = $(this);

    if (formID === "modal_form") {
      var errType = clikedForm.find(".select-btn-modal");
      if (!checkboxModal.checked) {
        $(checkboxLabelModal).addClass("error");
        return;
      }
    }

    if (formID === "footer_form") {
      errType = clikedForm.find(".select-btn");
      if (!checkbox.checked) {
        $(checkboxLabel).addClass("error");
        return;
      }
    }

    let errName = clikedForm.find("[name='name']");
    let errEmail = clikedForm.find("[name='email']");
    let errPhone = clikedForm.find("[name='phone']");
    let errNameText = clikedForm.find(".error-message.name");
    let errEmailText = clikedForm.find(".error-message.email");
    let errPhoneText = clikedForm.find(".error-message.phone");
    let errTypeText = clikedForm.find(".error-message.type");

    errNameText.html("");
    errEmailText.html("");
    errPhoneText.html("");
    errTypeText.html("");
    $(errName).removeClass("error");
    $(errEmail).removeClass("error");
    $(errPhone).removeClass("error");
    $(errType).removeClass("error");

    let emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    if (clikedForm.find("[name='name']").val() == "") {
      $(errName).addClass("error");
      errNameText.html("Name can't be empty!");
      return;
    }
    if (!phoneFormValid) {
      $(errPhone).addClass("error");
      errPhoneText.html("Please enter a valid number below!!");
      return;
    }
    if (clikedForm.find("[name='phone']").val() == "") {
      $(errPhone).addClass("error");
      errPhoneText.html("Phone can't be empty!");
      return;
    }
    if (!emailRegex.test(clikedForm.find("[name='email']").val())) {
      $(errEmail).addClass("error");
      errEmailText.html("Please enter a valid email!");
      return;
    }
    if (clikedForm.find("[name='email']").val() == "") {
      $(errEmail).addClass("error");
      errEmailText.html("Email can't be empty!");
      return;
    }
    if (clikedForm.find("[name='type']").val() == "") {
      $(errType).addClass("error");
      errTypeText.html("Please chose type of consulting!");
      return;
    }

    $.ajax({
      type: "POST",
      url: "./plugins/call-mail.php",
      data: formNm.serialize(),
      success: function () {
        $(".modal-form").removeClass("is-active");
        $(".successful").addClass("is-active");
        clikedForm.find("[name='name']").val("");
        clikedForm.find("[name='phone']").val("");
        clikedForm.find("[name='email']").val("");
        clikedForm.find("[name='type']").val("");
        clikedForm.find("[name='message']").val("");
      },
    });
    return false;
  });

  $(".modal-form.successful .ico-close-modal").on("click", f_close);
  function f_close() {
    $("body").removeClass("is-modal-open");
    $(".successful").removeClass("is-active");
  }
});
